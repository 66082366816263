(function () {
    angular.module('MonstaFTP').controller('HistoryController', HistoryController);

    HistoryController.$inject = ['$scope', 'historyFactory', '$rootScope', 'uiOperationFactory'];

    function HistoryController($scope, historyFactory, $rootScope, uiOperationFactory) {
        $scope.history = [];

        var vm = this;
        vm.recentHistory = [];
        vm.sortedHistory = [];

        $scope.$on('directory-changed', function () {
            updateHistory();
        });

        $scope.$on('history-changed', function () {
            updateHistory();
        });

        $scope.historyClick = function (path) {
            $rootScope.$broadcast('change-directory', path);
        };

        $scope.$on('items-deleted', function (ev, itemPaths) {
            itemPaths.map(function (path) {
                historyFactory.removeEntry(path);
            });
        });

        function updateHistory() {
            var fullHistory = historyFactory.getUniqueHistory(100, uiOperationFactory.currentDirectory);
            vm.recentHistory = fullHistory.slice(0, HISTORY_RECENT);
            vm.sortedHistory = fullHistory.sort();
        }
    }
}());