(function () {
    angular.module('MonstaFTP').controller('FileEditorController', FileEditorController);

    FileEditorController.$inject = ['$scope', '$rootScope', 'connectionFactory', 'jQuery', 'licenseFactory',
        'codeMirrorFactory', '$translate', 'uiOperationFactory', 'configurationFactory'];

    function FileEditorController($scope, $rootScope, connectionFactory, jQuery, licenseFactory, codeMirrorFactory,
                                  $translate, uiOperationFactory, configurationFactory) {
        'use strict';
        $scope.editorFiles = [];
        $scope.activeFile = null;
        $scope.pathOfTabToRemove = null;
        $scope.licenseFactory = licenseFactory;
        $scope.settings = {autoSave: false};

        var modalFileEditorId = '#modal-editor', vm = this, autoSaveTimeout = null, $filePickerMenu = null;

        vm.savedDirectories = [];
        vm.hideProUpgradeMessages = false;

        vm.show = show;
        vm.hide = hide;
        vm.setupAdvancedEditor = setupAdvancedEditor;
        vm.startEditingFile = startEditingFile;
        vm.ensureFileInScope = ensureFileInScope;
        vm.getFileIndexByPath = getFileIndexByPath;
        vm.filePathIsInScope = filePathIsInScope;
        vm.getEditorFileByPath = getEditorFileByPath;
        vm.updateFileContents = updateFileContents;
        vm.loadFileContents = loadFileContents;
        vm.removeFile = removeFile;
        vm.initiateConfirmTabClose = initiateConfirmTabClose;
        vm.confirmTabClose = confirmTabClose;
        vm.cancelTabClose = cancelTabClose;
        vm.contentPutFinish = contentPutFinish;
        vm.beginAutosave = beginAutosave;
        vm.fileListClick = fileListClick;
        vm.shouldShowProUpgrade = shouldShowProUpgrade;
        vm.itemsMoved = itemsMoved;

        $scope.activateTab = function (filePath, $event) {
            if ($event.target.tagName == 'BUTTON')
                return;

            $scope.activeFile = vm.getEditorFileByPath(filePath);

            if ($filePickerMenu != null)
                $filePickerMenu.removeClass('open')
        };

        $scope.closeTabForFile = function (fileName, filePath) {
            var file = vm.getEditorFileByPath(filePath);
            if (!file.dirty) {
                vm.removeFile(filePath);
                return false;
            }

            vm.initiateConfirmTabClose(fileName, filePath);
            return false;
        };

        $scope.textChange = function (filePath) {
            var file = vm.getEditorFileByPath(filePath);
            if (file == null)
                return;

            file.dirty = true;

            vm.beginAutosave.call(vm);
        };

        $scope.saveActiveFile = function () {
            if ($scope.activeFile == null)
                return;
            $scope.activeFile.saving = true;
            var path = $scope.activeFile.path, contents = $scope.activeFile.contents;
            connectionFactory.putFileContents(path, contents).then(function () {
                vm.contentPutFinish(path, true);
            }, function (response) {
                vm.contentPutFinish(path, false);
                showResponseError(response, 'file save', $rootScope, $translate);
            });
        };

        $scope.$on('file-editor:edit', function (ev, fileName, filePath) {
            vm.startEditingFile(fileName, filePath);
            vm.show();
        });

        $scope.$on('file-editor:show', function () {
            vm.show();
        });

        $scope.$on('logout', function () {
            $scope.editorFiles = [];
            $scope.activeFile = null;
        });

        $scope.$on('items-deleted', function (ev, deletedItems) {
            vm.itemsMoved(deletedItems);
        });

        $scope.$on('items-moved', function (ev, movedItems) {
            var moveSources = [];

            for(var i = 0; i < movedItems.length; ++i)
                moveSources.push(movedItems[i][0])

            vm.itemsMoved(moveSources);
        });

        function show() {
            vm.hideProUpgradeMessages = configurationFactory.getApplicationSetting('hideProUpgradeMessages');
            vm.savedDirectories = [];
            jQuery(modalFileEditorId).modal('show');
        }

        function hide() {
            if (vm.savedDirectories.indexOf(uiOperationFactory.currentDirectory) != -1)
                $rootScope.$broadcast('change-directory'); // refresh directory list if there was a save in current dir

            $rootScope.$broadcast('file-editor:hide', $scope.editorFiles.length);
            jQuery(modalFileEditorId).modal('hide');
        }

        function setupAdvancedEditor(fileName, filePath) {
            var codeMode = codeMirrorFactory.convertFilenameToMode(fileName);
            window.setTimeout(function () {
                if (codeMode != null) {
                    var editorItem = vm.getEditorFileByPath(filePath);

                    if (editorItem.cmSetup == true)
                        return;

                    var textAreaId = "editor_ta_" + filePath;
                    codeMirrorFactory.initiateCodeMirror(codeMode, document.getElementById(textAreaId), function (cm) {
                        editorItem.cmSetup = true;
                        editorItem.cm = cm;
                        cm.on('change', function (cMirror) {
                            editorItem.contents = cMirror.getValue();
                            $scope.textChange(editorItem.path);
                        });
                    });
                }
            }, 0);
        }

        function startEditingFile(fileName, filePath) {
            vm.ensureFileInScope(fileName, filePath, function () {
                $scope.activeFile = vm.getEditorFileByPath(filePath);
                if (licenseFactory.isLicensed())
                    vm.setupAdvancedEditor(fileName, filePath);
            });
        }

        function ensureFileInScope(fileName, filePath, contentsLoadedCallback) {
            if (vm.filePathIsInScope(filePath)) {
                if (contentsLoadedCallback)
                    contentsLoadedCallback();
                return true;
            }

            var editorFile = {
                name: fileName,
                path: filePath,
                contents: null,
                dirty: false,
                saving: false,
                cmSetup: false,
                cm: null
            };

            if (licenseFactory.isLicensed()) {
                $scope.editorFiles.push(editorFile);
                $scope.editorFiles.sort(function (a, b) {
                    return a.path.toLowerCase() < b.path.toLowerCase() ? -1 : 1;
                });
            } else
                $scope.editorFiles = [editorFile];

            vm.loadFileContents(filePath, contentsLoadedCallback);
            return false;
        }

        function getFileIndexByPath(filePath) {
            for (var fileIndex = 0; fileIndex < $scope.editorFiles.length; ++fileIndex)
                if ($scope.editorFiles[fileIndex].path == filePath)
                    return fileIndex;

            return null;
        }

        function filePathIsInScope(filePath) {
            return vm.getFileIndexByPath(filePath) != null;
        }

        function getEditorFileByPath(filePath) {
            var fileIndex = vm.getFileIndexByPath(filePath);
            return fileIndex == null ? null : $scope.editorFiles[fileIndex];
        }

        function updateFileContents(filePath, fileContents) {
            var file = vm.getEditorFileByPath(filePath);
            if (file == null)
                return;
            file.contents = fileContents;
        }

        function loadFileContents(filePath, contentsLoadedCallback) {
            var file = vm.getEditorFileByPath(filePath);
            if (file == null)
                return;

            connectionFactory.getFileContents(filePath).then(
                function (response) {
                    vm.updateFileContents(filePath, response.data.data);
                    if (contentsLoadedCallback)
                        contentsLoadedCallback();
                }, function (response) {
                    showResponseError(response, 'file load', $rootScope, $translate);
                });
        }

        function removeFile(filePath) {
            var fileIndex = vm.getFileIndexByPath(filePath);
            if (fileIndex == null)
                return;

            var closingFiles = $scope.editorFiles.splice(fileIndex, 1);

            if (closingFiles.length && closingFiles[0].cm) {
                closingFiles[0].cm.toTextArea();
                closingFiles[0].cm = null;
            }

            if ($scope.editorFiles.length == 0) {
                $scope.activeFile = null;
                vm.hide();
                return;
            }

            var newFileIndex = Math.min(fileIndex, $scope.editorFiles.length - 1);
            $scope.activeFile = $scope.editorFiles[newFileIndex];
        }


        function showTabCloseConfirm(confirmMessage) {
            $rootScope.$broadcast('modal-confirm:show', confirmMessage, vm.confirmTabClose, vm.cancelTabClose);
        }

        function initiateConfirmTabClose(fileName, filePath) {
            $scope.pathOfTabToRemove = filePath;
            $translate('EDITOR_CLOSE_CONFIRM_MESSAGE', {file_name: fileName}).then(showTabCloseConfirm,
                showTabCloseConfirm);
        }

        function confirmTabClose() {
            vm.removeFile($scope.pathOfTabToRemove);
            $scope.pathOfTabToRemove = null;
        }

        function cancelTabClose() {
            $scope.pathOfTabToRemove = null;
        }

        function contentPutFinish(filePath, success) {
            var file = vm.getEditorFileByPath(filePath);
            if (file == null)
                return;

            file.saving = false;
            if (success) {
                file.dirty = false;
                var dirName = filePath.replace(/\\/g, '/').replace(/\/[^\/]*\/?$/, '');
                if (dirName == '')
                    dirName = '/';
                if (vm.savedDirectories.indexOf(dirName) == -1)
                    vm.savedDirectories.push(dirName);
            }
        }

        function beginAutosave() {
            if (!$scope.settings.autoSave)
                return;

            if (autoSaveTimeout)
                window.clearTimeout(autoSaveTimeout);

            autoSaveTimeout = window.setTimeout(function () {
                autoSaveTimeout = null;
                $scope.saveActiveFile();
            }, AUTOSAVE_DELAY_MS);
        }

        function fileListClick($event) {
            if ($filePickerMenu == null) {
                $filePickerMenu = jQuery($event.target).parent();

                if ($event.target.tagName == "I")
                    $filePickerMenu = $filePickerMenu.parent();
            }

            $filePickerMenu.toggleClass('open');
        }

        function shouldShowProUpgrade() {
            if (vm.hideProUpgradeMessages === true)
                return false;

            return !licenseFactory.isLicensed();
        }

        function itemsMoved(deletedItems) {
            // this really means the item is no longer where we expected it (moved, renamed or deleted)
            var pathsToRemove = [];
            for (var deletedPathIndex = 0; deletedPathIndex < deletedItems.length; ++deletedPathIndex) {
                var deletedPath = deletedItems[deletedPathIndex];

                if (deletedPath.length == 0)
                    continue;

                var deletedPathAsDirectory = deletedPath.substr(deletedPath.length - 1) == "/" ? deletedPath : (deletedPath + "/");

                for (var editorFileIndex = 0; editorFileIndex < $scope.editorFiles.length; ++editorFileIndex) {
                    var editorFile = $scope.editorFiles[editorFileIndex];
                    if (deletedPath == editorFile.path)
                        pathsToRemove.push(editorFile.path);
                    else {
                        if(editorFile.path.length <= deletedPathAsDirectory.length )
                            continue;

                        if(editorFile.path.substr(0, deletedPathAsDirectory.length) == deletedPathAsDirectory)
                            pathsToRemove.push(editorFile.path);
                    }

                }
            }

            for(var removeIndex = 0; removeIndex < pathsToRemove.length; ++removeIndex) {
                vm.removeFile(pathsToRemove[removeIndex], true);
            }
        }
    }
}());