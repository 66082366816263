function FileTransferStats(totalBytes) {
    this.completedBytes = 0;
    this.previousCompletedBytes = 0;
    this.totalBytes = totalBytes;
    this.previousSampleTime = -1;
    this._transferRateSamples = [];
}

FileTransferStats.prototype.wasStarted = function () {
    this.previousSampleTime = Date.now();
};

FileTransferStats.prototype.updateTransferAmount = function (completedBytes) {
    if (Date.now() - this.previousSampleTime < TRANSFER_RATE_UPDATE_INTERVAL)
        return false;

    if (completedBytes < this.completedBytes)
        return false;

    if(completedBytes != this.totalBytes && (completedBytes - this.completedBytes <= TRANSFER_BYTES_MIN_UPDATE))
        return false; // limit update rate

    this.previousCompletedBytes = this.completedBytes;
    this.completedBytes = completedBytes;
    this.addTransferRate();
    return true;
};

FileTransferStats.prototype.addTransferRate = function () {
    if (this._transferRateSamples.length == TRANSFER_RATE_SAMPLES_MAX)
        this._transferRateSamples.splice(0, 1);

    this._transferRateSamples.push(this.getInstantaneousTransferRate());
};

FileTransferStats.prototype.calculateTransferRate = function () {
    if (this._transferRateSamples.length == 0)
        return 0;

    var sum = 0;

    this._transferRateSamples.map(function (rate) {
        sum += rate;
    });

    return sum / this._transferRateSamples.length;
};

FileTransferStats.prototype.getInstantaneousTransferRate = function () {
    var elapsedTime = Date.now() - this.previousSampleTime;
    this.previousSampleTime = Date.now();
    return (this.completedBytes - this.previousCompletedBytes) / (elapsedTime / 1000);
};

FileTransferStats.prototype.getTransferPercent = function () {
    if (this.totalBytes == 0 || this.totalBytes == null || this.completedBytes == null)
        return 0;

    return (100 * this.completedBytes) / this.totalBytes;
};

FileTransferStats.prototype.complete = function () {
    this.completedBytes = this.totalBytes;
};

FileTransferStats.prototype.hasBeenStarted = function () {
    return this.previousSampleTime != -1;
};