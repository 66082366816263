(function () {
    angular.module('MonstaFTP').controller('FileBrowserController', FileBrowserController);


    FileBrowserController.$inject = ['$scope', '$rootScope', 'connectionFactory', 'uiOperationFactory', '$window',
        'authenticationFactory', 'historyFactory', 'jQuery', '$timeout', 'directorySortingFactory', '$translate',
        'selectedItemsFactory', 'configurationFactory', 'localConfigurationFactory', 'rowMouseTrackerFactory'];

    function FileBrowserController($scope, $rootScope, connectionFactory, uiOperationFactory, $window,
                                   authenticationFactory, historyFactory, jQuery, $timeout, directorySortingFactory,
                                   $translate, selectedItemsFactory, configurationFactory, localConfigurationFactory,
                                   rowMouseTrackerFactory) {
        'use strict';
        var vm = this, CONTEXT_MENU_X_OFFSET = 7, CONTEXT_MENU_Y_OFFSET = 14, CONTEXT_MENU_INSET = 20,
            systemVars = null, loggedIn = false, editableExtensions = [], currentContextMenu = null,
            preventRowDeselect = false;

        $scope.directoryList = [];
        $scope.selectedItems = selectedItemsFactory.getSelectedItems();
        $scope.directoriesToDelete = null;
        $scope.filesToDelete = null;
        $scope.isHistoryChangeDir = false;
        $scope.sortName = 'name';
        $scope.sortReversed = false;
        vm.renameSource = null;
        vm.rowDragStartIndex = null;
        vm.isMetaDrag = false;
        vm.previousDragOverIndex = null;
        vm.uiOperationFactory = uiOperationFactory;

        vm.didLogout = didLogout;
        vm.doReorder = doReorder;
        vm.cancelDelete = cancelDelete;
        vm.performPaste = performPaste;
        vm.confirmDelete = confirmDelete;
        vm.deleteComplete = deleteComplete;
        vm.downloadSingle = downloadSingle;
        vm.initiateRename = initiateRename;
        vm.changeDirectory = changeDirectory;
        vm.downloadMultiple = downloadMultiple;
        vm.getSelectedPaths = getSelectedPaths;
        vm.initiateMakeItem = initiateMakeItem;
        vm.showDeleteConfirm = showDeleteConfirm;
        vm.sortDirectoryList = sortDirectoryList;
        vm.downloadFileAtPath = downloadFileAtPath;
        vm.fileRenameCallback = fileRenameCallback;
        vm.initiateCutOfPaths = initiateCutOfPaths;
        vm.makeItemOKCallback = makeItemOKCallback;
        vm.handleReorderChange = handleReorderChange;
        vm.initiateCopyOfPaths = initiateCopyOfPaths;
        vm.directoryListSuccess = directoryListSuccess;
        vm.refreshDirectoryList = refreshDirectoryList;
        vm.downloadSelectedFiles = downloadSelectedFiles;
        vm.handleChangeDirectory = handleChangeDirectory;
        vm.makeItemSuccessCallback = makeItemSuccessCallback;
        vm.makeItemFailureCallback = makeItemFailureCallback;
        vm.setupContextMenuEvents = setupContextMenuEvents;
        vm.initiateCutOfSelectedPaths = initiateCutOfSelectedPaths;
        vm.initiateCopyOfSelectedPaths = initiateCopyOfSelectedPaths;
        vm.initiateChmodOfSelectedItems = initiateChmodOfSelectedItems;
        vm.initiateDeleteOfSelectedPaths = initiateDeleteOfSelectedPaths;

        vm.rowMouseDown = rowMouseDown;
        vm.rowMouseMove = rowMouseMove;
        vm.rowMouseUp = rowMouseUp;
        vm.rowMouseLeave = rowMouseLeave;

        vm.setEditableExtensions = setEditableExtensions;
        vm.itemIsEditable = itemIsEditable;
        vm.navigateUpOneLevel = navigateUpOneLevel;
        vm.showProperties = showProperties;
        vm.handleBodyClick = handleBodyClick;

        $scope.reorder = function ($event) {
            vm.handleReorderChange($event.currentTarget.getAttribute('data-sort-name'));
        };

        $scope.initiateDeleteOfItem = function (item) {
            var itemPath = uiOperationFactory.joinNameToCurrentDirectory(item.name);

            if (item.isDirectory == true) {
                $scope.directoriesToDelete = [itemPath];
                $scope.filesToDelete = null;
            } else {
                $scope.filesToDelete = [itemPath];
                $scope.directoriesToDelete = null;
            }

            vm.showDeleteConfirm([item.name]);
        };

        $scope.downloadItem = function (item) {
            var fullFilePath = uiOperationFactory.joinNameToCurrentDirectory(item.name);
            vm.downloadFileAtPath(fullFilePath);
        };

        $scope.initiateCutOfItem = function (item) {
            var fullFilePath = uiOperationFactory.joinNameToCurrentDirectory(item.name);
            vm.initiateCutOfPaths([fullFilePath]);
        };

        $scope.initiateCopyOfItem = function (item) {
            var fullFilePath = uiOperationFactory.joinNameToCurrentDirectory(item.name);
            vm.initiateCopyOfPaths([fullFilePath]);
        };

        $scope.editItem = function (item) {
            var fullFilePath = uiOperationFactory.joinNameToCurrentDirectory(item.name);
            $rootScope.$broadcast('file-editor:edit', item.name, fullFilePath);
        };

        $scope.initiateRenameOfItem = function (item) {
            vm.renameSource = item.name;
            vm.initiateRename();
        };

        $scope.$on('change-directory:on-history', function (ev, path) {
            $scope.isHistoryChangeDir = true;
            vm.handleChangeDirectory(path);
        });

        $scope.$on('change-directory', function (ev, path) {
            $scope.isHistoryChangeDir = false;
            vm.handleChangeDirectory(path);
        });

        $scope.$on('change-directory:on-upload', function () {
            vm.handleChangeDirectory(null, true)
        });

        $scope.handleClick = function ($event, index) {
            $event.stopPropagation();
            var item = this.item;
            if (item.isDirectory) {
                var directoryPath = uiOperationFactory.joinNameToCurrentDirectory(item.name);
                vm.changeDirectory(directoryPath);
            } else {
                jQuery($event.target.parentNode.parentNode).find('.context-catcher-button').dropdown('toggle');
                selectedItemsFactory.selectItem(index);
            }
        };

        $scope.$on('footer-button-click', function (ev, buttonName) {
            // todo: this doesn't necessarily need to be here as it's a $rootScope.$bc so available anywhere
            switch (buttonName) {
                case 'download':
                    vm.downloadSelectedFiles();
                    break;
                case 'cut':
                    vm.initiateCutOfSelectedPaths();
                    break;
                case 'copy':
                    vm.initiateCopyOfSelectedPaths();
                    break;
                case 'paste':
                    vm.performPaste();
                    break;
                case 'delete':
                    vm.initiateDeleteOfSelectedPaths();
                    break;
                case 'new-folder':
                    vm.initiateMakeItem('folder');
                    break;
                case 'new-file':
                    vm.initiateMakeItem('file');
                    break;
                case 'chmod':
                    vm.initiateChmodOfSelectedItems();
                    break;
                default:
                    break;
            }
        });

        function postLogin() {
            if (systemVars != null && loggedIn == true)
                vm.changeDirectory(authenticationFactory.initialDirectory, true);
        }

        $scope.$on('login', function () {
            loggedIn = true;
            postLogin();
        });

        $scope.initiateChmodOfItem = function (item) {
            $rootScope.$broadcast('modal-permissions:show', [uiOperationFactory.joinNameToCurrentDirectory(item.name)],
                item.numericPermissions);
        };

        $scope.$on('selected-items-changed', function () {
            $scope.selectedItems = selectedItemsFactory.getSelectedItems();
        });

        $scope.$on('logout', function () {
            vm.didLogout();
        });

        $scope.$on('configuration:key-changed', function (ev, key, value) {
            if (key == 'editableFileExtensions') {
                vm.setEditableExtensions(value);
            }
        });

        configurationFactory.getSystemConfiguration().then(systemVarLoadSuccess, systemVarLoadFailure);

        function contextMenuStyleForEvent(ev) {
            return {
                position: "fixed",
                left: ev.clientX + CONTEXT_MENU_X_OFFSET + "px",
                top: ev.clientY + CONTEXT_MENU_Y_OFFSET + "px",
                'margin-left': 0,
                'margin-top': 0
            };
        }

        function fixContextMenuOffScreen(event, $dropdownMenu) {
            if (elementWillExtendPastWindowWidth(event.clientX + CONTEXT_MENU_X_OFFSET, $dropdownMenu))
                $dropdownMenu.css("margin-left", -($dropdownMenu.width() + CONTEXT_MENU_INSET));

            if (elementWillExtendPastWindowHeight(event.clientY + CONTEXT_MENU_Y_OFFSET, $dropdownMenu))
                $dropdownMenu.css("margin-top", -($dropdownMenu.height() + CONTEXT_MENU_INSET));
        }

        function setupContextMenuEvents() {
            jQuery(".context-catcher-tr").contextmenu(function (e) {
                var $tr = jQuery(this);
                var $dropdownMenu = $tr.find('.dropdown-menu').first();
                var rowIndex = $tr.data('index');
                preventRowDeselect = selectedItemsFactory.getSelectedItems().indexOf(rowIndex) != -1;
                $dropdownMenu.css(contextMenuStyleForEvent(e));

                jQuery(this).find('.context-catcher-button').dropdown('toggle');

                fixContextMenuOffScreen(e, $dropdownMenu);

                if(["DIV", "TR", "TD"].indexOf(e.target.tagName) == -1)
                     selectedItemsFactory.selectItem(rowIndex);

                return false;
            });

            var $fileToobar = jQuery('.file-toolbar');

            $fileToobar.on('shown.bs.dropdown', function () {
                var $dropdownMenu = jQuery(this).find('.dropdown-menu');
                currentContextMenu = $dropdownMenu;
                var $dropDownMenuParent = $dropdownMenu.parent().removeClass('dropup');

                if ($dropdownMenu.css('position') == 'fixed')  // it was shown with rightclick so don't mess with it
                    return;

                if ($dropdownMenu.offset().top + $dropdownMenu.outerHeight() > jQuery(window).height() - jQuery("#footer").height())
                    $dropDownMenuParent.addClass('dropup');
            });

            $fileToobar.on('hidden.bs.dropdown', function () {
                var $contextCatcherUl = jQuery(this).find('.dropdown-menu');
                $contextCatcherUl.attr('style', null);
                currentContextMenu = null;
            });
        }

        function elementWillExtendPastWindowWidth(xOffset, $element) {
            return xOffset + $element.width() > jQuery(window).width();
        }

        function elementWillExtendPastWindowHeight(yOffset, $element) {
            return yOffset + $element.height() > jQuery(window).height()
        }

        function doReorder() {
            $scope.directoryList = vm.sortDirectoryList($scope.directoryList, $scope.sortName, $scope.sortReversed);
        }

        function handleReorderChange(newSortName) {
            $scope.sortReversed = newSortName == $scope.sortName ? !$scope.sortReversed : false;
            $scope.sortName = newSortName;
            vm.doReorder();
        }

        function sortDirectoryList(directoryList, sortName, sortReversed) {
            var sortMethodName = null;
            switch (sortName) {
                case 'name':
                    sortMethodName = 'sortByName';
                    break;
                case 'modified':
                    sortMethodName = 'sortByModificationDate';
                    break;
                case 'size':
                    sortMethodName = 'sortBySize';
                    break;
                case 'type':
                    sortMethodName = 'sortByType';
                    break;
                default:
                    break;
            }

            if (sortMethodName == null)
                return directoryList;

            return directorySortingFactory[sortMethodName](directoryList, sortReversed);
        }

        function directoryListSuccess(path, response, change) {
            $scope.directoryList = vm.sortDirectoryList(response.data.data, $scope.sortName, $scope.sortReversed);
            uiOperationFactory.currentDirectory = path;
            selectedItemsFactory.clearSelection();

            if (change) {
                if (!$scope.isHistoryChangeDir)
                    historyFactory.addEntry(uiOperationFactory.currentDirectory);
                $scope.isHistoryChangeDir = false;
                $rootScope.$broadcast('directory-changed', path);
                authenticationFactory.initialDirectory = path;
                authenticationFactory.saveSettings();
            }
            $timeout(function () {
                vm.setupContextMenuEvents();
                //DOM has finished rendering
            });
        }

        function changeDirectory(path, isFirstLoad, allowTimeout) {
            localConfigurationFactory.getApplicationSettings().then(
                function () {
                    var handleError;

                    if (allowTimeout) {
                        handleError = function () {
                            // this is on upload finish which might fail sometimes but we don't care
                        };
                    } else {
                        handleError = function (response) {
                            if (isFirstLoad)
                                vm.changeDirectory("/");
                            else
                                showResponseError(response, 'directory change', $rootScope, $translate);
                        };
                    }

                    connectionFactory.listDirectory(path, localConfigurationFactory.getConfigurationItem('showDotFiles')).then(
                        function (response) {
                            vm.directoryListSuccess(path, response, path != uiOperationFactory.currentDirectory);
                        },
                        handleError
                    );
                },
                function (response) {
                    showResponseError(response, 'application settings load', $rootScope, $translate);
                });
        }

        function refreshDirectoryList() {
            vm.changeDirectory(uiOperationFactory.currentDirectory);
        }

        function filesReadyForDownloadCallback(response) {
            if (responseIsUnsuccessful(response)) {
                showResponseError(response, 'file download', $rootScope, $translate);
                return;
            }

            var fileKey = response.data.fileKey;
            $window.location = DOWNLOAD_PATH + '?fileKey=' + fileKey;
        }

        function fileFetchFailureCallback(response) {
            showResponseError(response, 'file download', $rootScope, $translate);
            $rootScope.$broadcast('modal-prompt:clear-busy');
        }

        function downloadFileAtPath(path) {
            connectionFactory.fetchFile(path).then(filesReadyForDownloadCallback, fileFetchFailureCallback);
        }

        function downloadMultiple() {
            var selectedItems = selectedItemsFactory.getSelectedItems();
            var itemNames = [];

            selectedItems.map(function (itemIndex) {
                itemNames.push($scope.directoryList[itemIndex].name);
            });

            connectionFactory.downloadMultipleFiles(uiOperationFactory.currentDirectory, itemNames).then(
                filesReadyForDownloadCallback, fileFetchFailureCallback);
        }

        function downloadSingle() {
            var fullFilePath = uiOperationFactory.joinNameToCurrentDirectory(
                $scope.directoryList[selectedItemsFactory.getSelectedItems()[0]].name);
            vm.downloadFileAtPath(fullFilePath);
        }

        function downloadSelectedFiles() {
            var selectedItems = selectedItemsFactory.getSelectedItems();
            if (selectedItems.length > 1 || $scope.directoryList[selectedItems[0]].isDirectory) {
                vm.downloadMultiple();
            } else {
                vm.downloadSingle();
            }
        }

        function getSelectedPaths() {
            var selectedPathCopy = selectedItemsFactory.getSelectedItems().slice();
            selectedPathCopy.sort(function (a, b) {
                return a - b;
            });
            return selectedPathCopy.map(function (pathIndex) {
                return uiOperationFactory.joinNameToCurrentDirectory($scope.directoryList[pathIndex].name);
            });
        }

        function initiateCopyOfPaths(paths) {
            if (paths.length == 0)
                return;

            uiOperationFactory.setCopySource(paths);
        }

        function initiateCutOfPaths(paths) {
            if (paths.length == 0)
                return;

            uiOperationFactory.setCutSource(paths);
        }

        function initiateCutOfSelectedPaths() {
            vm.initiateCutOfPaths(vm.getSelectedPaths());
        }

        function initiateCopyOfSelectedPaths() {
            vm.initiateCopyOfPaths(vm.getSelectedPaths());
        }

        function performPaste() {
            if (uiOperationFactory.cutSource == null && uiOperationFactory.copySource == null)
                return;

            var sourcePaths, operation;

            if (uiOperationFactory.cutSource != null) {
                sourcePaths = uiOperationFactory.cutSource;
                operation = 'rename';
            } else {
                sourcePaths = uiOperationFactory.copySource;
                operation = 'copy';
            }

            for (var pathIndex = 0; pathIndex < sourcePaths.length; ++pathIndex) {
                var sourcePath = sourcePaths[pathIndex];
                var splitSourceFileName = sourcePath.split('/');
                var sourceFileName = splitSourceFileName[splitSourceFileName.length - 1];
                var destinationPath = uiOperationFactory.joinNameToCurrentDirectory(sourceFileName);
                connectionFactory[operation](sourcePath, destinationPath).then(function () {
                    uiOperationFactory.pasteComplete();
                    vm.changeDirectory(uiOperationFactory.currentDirectory);
                    if (operation == 'rename')
                        $rootScope.$broadcast('items-moved', [[sourcePath, destinationPath]]);
                }, function (response) {
                    showResponseError(response, 'file paste', $rootScope, $translate);
                });
            }
        }

        function deleteComplete() {
            var allItems = [].concat($scope.directoriesToDelete || [], $scope.filesToDelete || []);

            $rootScope.$broadcast("items-deleted", allItems);

            $scope.directoriesToDelete = null;
            $scope.filesToDelete = null;
            vm.changeDirectory(uiOperationFactory.currentDirectory);
        }

        function confirmDelete() {
            var itemsToDelete = [];

            if ($scope.filesToDelete != null) {
                $scope.filesToDelete.map(function (path) {
                    if (uiOperationFactory.isCutOrCopySource(path))
                        uiOperationFactory.clearCutAndCopySource();

                    itemsToDelete.push([path, false]);
                });
            }

            if ($scope.directoriesToDelete != null) {
                $scope.directoriesToDelete.map(function (path) {
                    if (uiOperationFactory.isCutOrCopySource(path))
                        uiOperationFactory.clearCutAndCopySource();

                    itemsToDelete.push([path, true]);
                });
            }
            connectionFactory.deleteMultiple(itemsToDelete).then(function () {
                vm.deleteComplete();
            }, function (response) {
                showResponseError(response, 'item deletion', $rootScope, $translate);
                vm.deleteComplete();
            });

            /*
             the inner function is needed for testing instead of calling connectionFactory.deleteFile directly in the map
             because the function is actually called with 3 args (item, index, arr) and the real function discards the
             second 2. the spy object doesn't know this so thinks its being called wrong
             */
        }

        function cancelDelete() {
            $scope.filesToDelete = null;
            $scope.directoriesToDelete = null;
        }

        function showTranslatedDeleteConfirm(translatedMessage) {
            $rootScope.$broadcast('modal-confirm:show', translatedMessage, vm.confirmDelete, vm.cancelDelete);
        }

        function showDeleteConfirm(fileNames) {
            $translate('DELETE_CONFIRM_MESSAGE', {
                item_count: fileNames.length
            }).then(function (translatedMessage) {
                showTranslatedDeleteConfirm(translatedMessage);
            }, function (message) {
                showTranslatedDeleteConfirm(message);
            });
        }

        function initiateDeleteOfSelectedPaths() {
            var selectedItems = selectedItemsFactory.getSelectedItems();
            if (selectedItems.length == 0)
                return;

            var fileNames = [];

            $scope.directoriesToDelete = [];
            $scope.filesToDelete = [];

            for (var i = 0; i < selectedItems.length; ++i) {
                var itemIndex = selectedItems[i];
                var item = $scope.directoryList[itemIndex];

                fileNames.push(item.name);

                var fullPath = uiOperationFactory.joinNameToCurrentDirectory(item.name);

                if (item.isDirectory)
                    $scope.directoriesToDelete.push(fullPath);
                else
                    $scope.filesToDelete.push(fullPath);
            }

            vm.showDeleteConfirm(fileNames);
        }

        function clearNewItemTempVars() {
            vm.newItemPath = null;
            $scope.makeItemType = null;
        }

        function makeItemSuccessCallback() {
            $rootScope.$broadcast('modal-prompt:hide');
            vm.refreshDirectoryList();

            if ($scope.makeItemType == 'folder') {
                clearNewItemTempVars();
                return;
            }

            localConfigurationFactory.getApplicationSettings().then(function () {
                var shouldEditItem = localConfigurationFactory.getConfigurationItem('editNewFilesImmediately');
                if (shouldEditItem && vm.itemIsEditable(false, vm.newItemPath)) {
                    var splitPath = vm.newItemPath.split('/');
                    $rootScope.$broadcast('file-editor:edit', splitPath[splitPath.length - 1], vm.newItemPath);
                }
                clearNewItemTempVars();
            }, function (response) {
                clearNewItemTempVars();
                showResponseError(response, 'rename', $rootScope, $translate);
            });
        }

        function makeItemFailureCallback(errorMessage, context) {
            $rootScope.$broadcast('modal-prompt:clear-busy');
            $translate(['NEW_ITEM_FAILURE_PRECEDING_MESSAGE', errorMessage], context).then(function (translations) {
                displayPromptError(translations['NEW_ITEM_FAILURE_PRECEDING_MESSAGE'] + ' ' +
                    translations[errorMessage])
            });
        }

        function getFileNameError(fileName) {
            if (!validateFileNameNonEmpty(fileName))
                return 'FILE_NAME_EMPTY_MESSAGE';

            if (!validateFileNameContainsNoSlash(fileName))
                return 'FILE_NAME_CONTAINS_SLASH_MESSAGE';

            return null;
        }

        function translateAndDisplayPromptError(promptErrorID, context) {
            $translate(promptErrorID, context).then(displayPromptError, displayPromptError);

        }

        function makeItemOKCallback(itemName) {
            $rootScope.$broadcast('modal-prompt:clear-error');

            var itemNameError = getFileNameError(itemName);

            if (itemNameError != null) {
                translateAndDisplayPromptError(itemNameError, {item_type: $scope.makeItemType});
                return;
            }

            vm.newItemPath = uiOperationFactory.joinNameToCurrentDirectory(itemName);

            $rootScope.$broadcast('modal-prompt:set-busy', 'CREATING_ACTIVITY_STATUS');

            var promise;
            if ($scope.makeItemType == 'folder')
                promise = connectionFactory.makeDirectory(vm.newItemPath);
            else if ($scope.makeItemType == 'file')
                promise = connectionFactory.putFileContents(vm.newItemPath, '');

            promise.then(function () {
                vm.makeItemSuccessCallback();
            }, function (response) {
                var action = $scope.makeItemType + ' make';
                vm.makeItemFailureCallback(parseErrorResponse(response, action), {
                    item_type: $scope.makeItemType,
                    action: action
                });
            });
        }

        function initiateMakeItem(itemType) {
            $scope.makeItemType = itemType;
            var ucItemType = $scope.makeItemType.capitalizeFirstLetter();

            $translate(['NEW_ITEM_PROMPT_TITLE', 'NEW_ITEM_NAME_PLACEHOLDER'], {item_type: ucItemType}).then(
                function (translations) {
                    $rootScope.$broadcast('modal-prompt:show', translations.NEW_ITEM_PROMPT_TITLE, '',
                        translations.NEW_ITEM_NAME_PLACEHOLDER, vm.makeItemOKCallback);
                });
        }

        function initiateChmodOfSelectedItems() {
            if ($scope.selectedItems.length == 0)
                return;

            var itemPaths = [], numericPerms = -1;

            $scope.selectedItems.map(function (itemIndex) {
                var item = $scope.directoryList[itemIndex];
                itemPaths.push(uiOperationFactory.joinNameToCurrentDirectory(item.name));
                if (numericPerms == -1)
                    numericPerms = item.numericPermissions;
                else if (item.numericPermissions != numericPerms)
                    numericPerms = 0;
            });

            $rootScope.$broadcast('modal-permissions:show', itemPaths, numericPerms);
        }

        function displayPromptError(error) {
            $rootScope.$broadcast('modal-prompt:set-error', error);
        }

        function fileRenameCallback(finalValue, initialValue) {
            $rootScope.$broadcast('modal-prompt:clear-error');

            if (initialValue == finalValue)
                return;

            var itemNameError = getFileNameError(finalValue);

            if (itemNameError != null) {
                translateAndDisplayPromptError(itemNameError, {item_type: 'item'});
                return;
            }

            $rootScope.$broadcast('modal-prompt:set-busy', 'RENAMING_ACTIVITY_STATUS');

            var sourcePath = uiOperationFactory.joinNameToCurrentDirectory(initialValue);
            var destinationPath = uiOperationFactory.joinNameToCurrentDirectory(finalValue);
            connectionFactory.rename(sourcePath, destinationPath).then(
                function (response) {
                    $rootScope.$broadcast('modal-prompt:clear-busy');
                    if (responseIsUnsuccessful(response)) {
                        showResponseError(response, 'rename', $rootScope, $translate);
                        return;
                    }
                    vm.refreshDirectoryList();
                    $rootScope.$broadcast('items-moved', [[sourcePath, destinationPath]]);
                    $rootScope.$broadcast('modal-prompt:hide');
                },
                function (response) {
                    $rootScope.$broadcast('modal-prompt:clear-busy');
                    showResponseError(response, 'rename', $rootScope, $translate);
                }
            );
            vm.renameSource = null;
        }

        function showRenamePrompt(translations) {
            $rootScope.$broadcast('modal-prompt:show', translations.RENAME_FILE_PROMPT_TITLE, vm.renameSource,
                translations.RENAME_FILE_NAME_PLACEHOLDER, vm.fileRenameCallback);
        }

        function initiateRename() {
            $translate(['RENAME_FILE_PROMPT_TITLE', 'RENAME_FILE_NAME_PLACEHOLDER']).then(showRenamePrompt,
                showRenamePrompt);
        }

        function handleChangeDirectory(path, allowTimeout) {
            if (path == uiOperationFactory.currentDirectory)
                return;
            if (typeof path == 'undefined' || path == null)
                path = uiOperationFactory.currentDirectory;
            vm.changeDirectory(path, false, allowTimeout);
        }

        function didLogout() {
            uiOperationFactory.currentDirectory = null;
            authenticationFactory.initialDirectory = null;
            $scope.directoryList = [];
            historyFactory.clearHistory();
        }

        function systemVarLoadSuccess(vars) {
            systemVars = vars;
            vm.setEditableExtensions(configurationFactory.getApplicationSetting('editableFileExtensions'));
            postLogin();
        }

        function systemVarLoadFailure(response) {
            showResponseError(response, "system var load", $rootScope, $translate);
        }

        function setEditableExtensions(editableExtensionStr) {
            var splitExtensions = editableExtensionStr.split(",");
            editableExtensions = [];
            for (var i = 0; i < splitExtensions.length; ++i) {
                var trimmedExtension = splitExtensions[i].trim().toLowerCase();

                if (trimmedExtension != '')
                    editableExtensions.push(trimmedExtension);
            }
        }

        function itemIsEditable(isDirectory, itemPath) {
            if (isDirectory)
                return false;

            var splitPath = itemPath.split('/');
            var itemName = splitPath[splitPath.length - 1];
            var extension = extractFileExtension(itemName);

            if (extension == '')
                return true;

            return editableExtensions.indexOf(extension) != -1;
        }

        function navigateUpOneLevel() {
            vm.changeDirectory(parentPath(uiOperationFactory.currentDirectory), false);
        }

        function showProperties(item) {
            $rootScope.$broadcast('modal-properties:show', item);
        }

        function handleBodyClick($event) {
            if ($event.which == 3 && $event.target.id == "files") {// right click
                $scope.hasPasteSource = uiOperationFactory.cutSource != null || uiOperationFactory.copySource != null;

                if (currentContextMenu)
                    currentContextMenu.dropdown('toggle');

                var $extraDropdownButton = jQuery('#extras-dropdown-button');

                $extraDropdownButton.dropdown('toggle');
                var $dropdownMenu = jQuery('#extras-dropdown');
                $dropdownMenu.css(contextMenuStyleForEvent($event));

                fixContextMenuOffScreen($event, $dropdownMenu);

                return false;
            }
        }

        function rowMouseDown($event) {
            rowMouseTrackerFactory.mouseDown($event);
        }

        function rowMouseMove($event, $index) {
            rowMouseTrackerFactory.mouseMove($event, $index);
        }

        function rowMouseUp($index) {
            rowMouseTrackerFactory.mouseUp($index, preventRowDeselect);
            preventRowDeselect = false;
        }

        function rowMouseLeave($event) {
            rowMouseTrackerFactory.mouseLeave($event);
        }
    }
}());