(function () {
    angular.module('MonstaFTP').factory('requestLoginFactory', requestLoginFactory);

    requestLoginFactory.$inject = ["$location"];

    function requestLoginFactory($location) {
        var factory = {};

        factory.encodeConfiguration = encodeConfiguration;
        factory.decodeConfiguration = decodeConfiguration;
        factory.getPreHashURL = getPreHashURL;
        factory.getConfigURL = getConfigURL;
        factory.compactConfigKeys = compactConfigKeys;
        factory.uncompactConfigKeys = uncompactConfigKeys;
        factory.decodePostHash = decodePostHash;
        factory.getConfigFromCurrentURL = getConfigFromCurrentURL;
        factory.getFormFieldHTML = getFormFieldHTML;

        function encodeConfiguration(type, configuration) {
            var configDict = {
                t: type,
                c: factory.compactConfigKeys(configuration)
            };
            return encodeURIComponent(b64EncodeUnicode(JSON.stringify(configDict)));
        }

        function decodeConfiguration(encodedConfiguration) {
            var configDict = JSON.parse(b64DecodeUnicode(decodeURIComponent(encodedConfiguration)));

            return {
                type: configDict.t,
                configuration: factory.uncompactConfigKeys(configDict.c)
            };
        }

        function decodePostHash(postHash) {
            if (isEmpty(postHash))
                return null;

            if (postHash.substr(0, 1) == "/")
                postHash = postHash.substr(1);

            var splitURL = postHash.split("/");

            if (splitURL.length < 4 || splitURL[0] != 'c')
                return null;

            var host = splitURL[1], username = splitURL[2], config = splitURL[3], decodedConfiguration = null;

            try {
                decodedConfiguration = factory.decodeConfiguration(config);
            } catch (e) {
                return null;
            }

            if (host != "_")
                decodedConfiguration.configuration.host = host;

            if (username != "_") {
                var usernameKey = decodedConfiguration.type == 'sftp' ? 'remoteUsername' : 'username';

                decodedConfiguration.configuration[usernameKey] = username;
            }

            return decodedConfiguration;
        }

        function getPreHashURL() {
            var absURL = $location.absUrl();
            var splitURL = absURL.split('#');
            return splitURL[0];
        }

        function getConfigURL(type, configuration) {
            if(configuration == null)
                return null;

            configuration = angular.copy(configuration);
            var host = null, username = null;

            if (configuration.hasOwnProperty('host')) {
                host = configuration.host;
                delete configuration.host;
            }

            var usernameKey = type == 'sftp' ? 'remoteUsername' : 'username';

            if (configuration.hasOwnProperty(usernameKey)) {
                username = configuration[usernameKey];
                delete configuration[usernameKey];
            }

            if (isEmpty(host))
                host = '_';
            else
                host = encodeURIComponent(host);

            if (isEmpty(username))
                username = '_';
            else
                username = encodeURIComponent(username);

            var postHash = '/c/' + host + '/' + username + '/' + factory.encodeConfiguration(type, configuration);

            return factory.getPreHashURL() + "#" + postHash;
        }

        function getConfigFromCurrentURL() {
            var absURL = $location.absUrl();
            var splitURL = absURL.split('#');
            if(splitURL.length == 1)
                return null;

            return decodePostHash(splitURL[1]);
        }

        function getTransformLookup(isCompact) {
            var compactLookup = [
                ["passive", "v"],
                ["ssl", "s"],
                ["password", "p"],
                ["initialDirectory", "i"],
                ["port", "o"],
                ["authenticationModeName", "m"],
                ["privateKeyFilePath", "r"],
                ["publicKeyFilePath", "q"]
            ];

            var to = isCompact ? 1 : 0;
            var from = isCompact ? 0 : 1;

            var transformLookup = {};

            for (var i = 0; i < compactLookup.length; ++i) {
                transformLookup[compactLookup[i][from]] = compactLookup[i][to];
            }

            return transformLookup;
        }

        function compactAndUncompact(isCompact, toTransform) {
            var transformResult = {};

            var transformLookup = getTransformLookup(isCompact);

            for (var key in toTransform) {
                if (!toTransform.hasOwnProperty(key))
                    continue;

                var value = toTransform[key];

                if (transformLookup.hasOwnProperty(key))
                    key = transformLookup[key];

                if (isCompact) {
                    if (value === true)
                        value = 1;
                    else if (value === false)
                        value = 0;
                } else {
                    if (value === 1)
                        value = true;
                    if (value === 0)
                        value = false;
                }

                transformResult[key] = value;
            }

            return transformResult;
        }

        function compactConfigKeys(config) {
            return compactAndUncompact(true, config);
        }

        function uncompactConfigKeys(config) {
            return compactAndUncompact(false, config);
        }

        function getFormFieldHTML(name, value) {
            var entityMap = {
                "&": "&amp;",
                "<": "&lt;",
                ">": "&gt;",
                '"': '&quot;'
            };

            value = String(value).replace(/[&<>"]/g, function (s) {
                return entityMap[s];
            });

            return '<input type="hidden" name="'+ name +'" value="' + value + '">'
        }

        return factory;
    }
}());