(function () {
    angular.module('MonstaFTP').directive('uploadProgressBar', uploadProgressBar);

    uploadProgressBar.$inject = ['transfer_percentFilter'];

    function uploadProgressBar(transfer_percentFilter) {
        function getProgressBarHtml(upload) {
            var indeterminateClasses = '';

            if (upload.stats.completedBytes == upload.stats.totalBytes)
                indeterminateClasses = ' progress-bar-striped active';

            var transferPercent = transfer_percentFilter(upload);

            return '<div class="progress-bar progress-bar-success' + indeterminateClasses + '" ' +
                'role="progressbar" aria-valuenow="' + transferPercent + '" ' +
                'aria-valuemin="0" aria-valuemax="100" style="width: ' + transferPercent + '%;"></div>';
        }

        return {
            restrict: 'E',
            scope: {
                upload: '='
            },
            template: '<div></div>',
            link: function ($scope, element, attrs) {
                var updatePBarHtml = function () {
                    element.html(getProgressBarHtml($scope.upload));
                };

                updatePBarHtml();

                $scope.$watch('upload.stats', function () {
                    updatePBarHtml();
                }, true);
            }
        };
    }
}());