(function () {
    var TRANSFER_UI_UPDATE_DELAY = 200;  // only update the UI after this many MS

    angular.module('MonstaFTP').controller('ModalTransferController', ModalTransferController);

    ModalTransferController.$inject = ['uploadFactory', '$rootScope', '$scope', 'jQuery', '$timeout',
        '$filter'];

    function ModalTransferController(uploadFactory, $rootScope, $scope, jQuery, $timeout, $filter) {
        var modalId = '#modal-transfers', vm = this, updateApplyTimeout = null;

        vm.updateUploads = updateUploads;
        vm.uploadFinished = uploadFinished;
        vm.show = show;
        vm.hide = hide;
        vm.abortItem = abortItem;
        vm.abortAll = abortAll;
        vm.fsFilter = $filter('file_size');

        vm.uploads = [];
        vm.itemToAbort = null;

        uploadFactory.updateCallback = vm.updateUploads;

        $scope.$on('upload:load', this.uploadFinished);
        $scope.$on('upload:add', this.show);

        function updateUploads(instantUpdate) {
            var uploads = uploadFactory.getUploads();
            if (uploads.length == 0) {
                updateApplyTimeout = null;
                vm.uploads = uploads;
                vm.hide();
            } else {
                var timeout = instantUpdate ? 0 : TRANSFER_UI_UPDATE_DELAY;

                if(instantUpdate && updateApplyTimeout != null) {
                    clearTimeout(updateApplyTimeout);
                    updateApplyTimeout = null;
                }

                if(updateApplyTimeout == null) {
                    vm.uploads = uploads;
                    updateApplyTimeout = $timeout(function () {
                        $scope.$apply();
                        updateApplyTimeout = null;
                    }, timeout);
                }
            }
        }

        function uploadFinished() {
            vm.updateUploads();
            if(vm.uploads.length == 0)
                $rootScope.$broadcast('change-directory'); // refresh directory
            else
                $rootScope.$broadcast('change-directory:on-upload'); // refresh directory which is allowed to fail
        }

        function show() {
            vm.updateUploads();
            jQuery(modalId).modal('show');
        }

        function hide() {
            jQuery(modalId).modal('hide');
        }

        function abortItem(item) {
            uploadFactory.abortItem(item);
            vm.updateUploads();
        }

        function abortAll() {
            uploadFactory.abortAll();
        }
    }
}());