(function () {
    angular.module('MonstaFTP').controller('ModalLoginLinkController', ModalLoginLinkController);

    ModalLoginLinkController.$inject = ["$rootScope", "$scope", "jQuery", "requestLoginFactory", "$element", "$window",
        "$translate"];

    function ModalLoginLinkController($rootScope, $scope, jQuery, requestLoginFactory, $element, $window, $translate) {
        var vm = this, modalId = '#modal-login-link';

        vm.show = show;
        vm.hide = hide;
        vm.copy = copy;

        $scope.$on('modal-login-link:show', function(ev, type, configuration){
            vm.type = type;
            vm.configuration = configuration;
            vm.show();
        });

        function show() {
            vm.configURL = requestLoginFactory.getConfigURL(vm.type, vm.configuration);
            vm.supportsCopy = browserSupportsCopy();
            jQuery(modalId).modal('show');
        }

        function hide() {
            jQuery(modalId).modal('hide');
            $rootScope.$broadcast('modal-login:show');
        }

        function alertOnCopyFailure(textArea, message) {
            alert(message);
            textArea.select();
        }

        function copy() {
            var textArea = $element.find('textarea')[0];
            textArea.select();
            var copySuccess = false;
            try {
                copySuccess = document.execCommand('copy');
            } catch (err) {
                copySuccess = false;
            }

            if(!copySuccess) {
                $translate("COPY_FAILURE_MESSAGE").then(function(copyFailureMessage){
                    alertOnCopyFailure(textArea, copyFailureMessage);
                }, function () {
                    alertOnCopyFailure(textArea, "Unfortunately your browser does not support automatic copying, " +
                        "please copy the address from the text box.");
                });
            }
        }

        function browserSupportsCopy() {
            var userAgent = $window.navigator.userAgent;
            if(/chrome/i.test(userAgent))
                return true;

            if(/safari/i.test(userAgent))
                return false;

            if(document.queryCommandEnabled == undefined)
                return false;

            return document.queryCommandEnabled('copy');
        }

        jQuery(modalId).on('shown.bs.modal', function(){
            jQuery(this).find("textarea").select();
        });
    }
}());

