(function () {
    angular.module('MonstaFTP').controller('ModalAddonsController', ModalAddonsController);

    ModalAddonsController.$inject = ['jQuery', 'licenseFactory', '$scope', '$filter', 'connectionFactory',
        '$translate'];

    function ModalAddonsController(jQuery, licenseFactory, $scope, $filter, connectionFactory, $translate) {
        var modalId = '#modal-addons', vm = this;

        vm.show = show;
        vm.updateLicense = updateLicense;

        vm.models = {license: ''};
        vm.licenseUpdateError = null;

        $scope.$on('modal-addons:show', function () {
            vm.show();
        });

        $scope.$on('license-loaded', function () {
           refreshLicenseData();
        });

        function updateLicense() {
            vm.licenseUpdateError = null;
            connectionFactory.updateLicense(vm.models.license).then(function () {
                licenseFactory.getLicense();
                jQuery('#addon-tab-links a:first').tab('show');
                vm.models.license = '';
            }, function (response) {
                var localizedError = response.data.localizedErrors[0];

                $translate(localizedError.errorName, localizedError.context).then(function (translatedMessage) {
                    vm.licenseUpdateError = translatedMessage;
                }, function () {
                    vm.licenseUpdateError = errorMessage;
                });
            });
        }

        function refreshLicenseData() {
            vm.formattedExpiryDate = $filter('date')(licenseFactory.expiryDate, "d MMMM, yyyy");
            vm.licenseExpired = licenseFactory.isLicenseExpired();
            vm.isLicensed = licenseFactory.isLicensed();
            vm.isTrialLicense = licenseFactory.isTrialLicense();
        }

        function show() {
            refreshLicenseData();
            jQuery(modalId).modal('show');
        }
    }
}());