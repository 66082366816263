(function () {
    angular.module('MonstaFTP').factory('connectionFactory', connectionFactory);

    connectionFactory.$inject = ['$http', 'authenticationFactory'];

    function connectionFactory($http, authenticationFactory) {
        var NETWORK_TIMEOUT_SECONDS = 30;
        var IGNORE_TIMEOUT_TYPES = [
            "downloadMultipleFiles",
            "fetchRemoteFile",
            "copy",
            "extractArchive",
            "deleteMultiple"
        ];

        var addRemoteFileRequest = function (request, actionName, remotePath) {
            request['actionName'] = actionName;
            request['context'] = {
                'remotePath': remotePath
            };
        };

        var addSourceDestinationRequest = function (request, actionName, source, destination) {
            request['actionName'] = actionName;
            request['context'] = {
                'source': source,
                'destination': destination
            };
        };

        return {
            _sendRequest: function (requestBody) {
                var timeout = IGNORE_TIMEOUT_TYPES.indexOf(requestBody.actionName) == -1 ? NETWORK_TIMEOUT_SECONDS * 1000 : null;

                var req = {
                    method: 'POST',
                    url: API_PATH,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    transformRequest: function (obj) {
                        var str = [];
                        for (var p in obj) {
                            if (!obj.hasOwnProperty(p))
                                continue;
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(JSON.stringify(obj[p])));
                        }
                        return str.join("&");
                    },
                    timeout: timeout,
                    data: {
                        request: requestBody
                    }
                };

                if (DEBUG)
                    console.log(req);

                return $http(req);
            },
            getRequestBody: function () {
                var config = angular.copy(authenticationFactory.getActiveConfiguration());

                var defaultsForConnectionType = g_ConnectionDefaults[authenticationFactory.connectionType];

                if (defaultsForConnectionType) {
                    for (var defaultName in defaultsForConnectionType) {
                        if (!defaultsForConnectionType.hasOwnProperty(defaultName))
                            continue;

                        if (config[defaultName] == null || config[defaultName] == undefined || config[defaultName] == '')
                            config[defaultName] = defaultsForConnectionType[defaultName];
                    }
                }

                return {
                    connectionType: authenticationFactory.connectionType,
                    configuration: config
                };
            },
            getEmptyRequestBody: function () {
                return {connectionType: null, configuration: null};
            },
            getListDirectoryRequest: function (request, path, showHidden) {
                request['actionName'] = 'listDirectory';
                request['context'] = {
                    path: path,
                    showHidden: showHidden
                };
            },
            getFetchFileRequest: function (request, remotePath) {
                addRemoteFileRequest(request, 'fetchFile', remotePath);
            },
            getMakeDirectoryRequest: function (request, remotePath) {
                addRemoteFileRequest(request, 'makeDirectory', remotePath);
            },
            getDeleteDirectoryRequest: function (request, remotePath) {
                addRemoteFileRequest(request, 'deleteDirectory', remotePath);
            },
            getDeleteFileRequest: function (request, remotePath) {
                addRemoteFileRequest(request, 'deleteFile', remotePath);
            },
            getCopyRequest: function (request, source, destination) {
                addSourceDestinationRequest(request, 'copy', source, destination);
            },
            getRenameRequest: function (request, source, destination) {
                addSourceDestinationRequest(request, 'rename', source, destination);
            },
            getChangePermissionsRequest: function (request, remotePath, mode) {
                addRemoteFileRequest(request, 'changePermissions', remotePath);
                request['context']['mode'] = mode;
            },
            getPutFileContentsRequest: function (request, remotePath, fileContents) {
                request['actionName'] = 'putFileContents';
                request['context'] = {remotePath: remotePath, fileContents: fileContents};
            },
            getGetFileContentsRequest: function (request, remotePath) {
                request['actionName'] = 'getFileContents';
                request['context'] = {remotePath: remotePath};
            },
            getTestConnectAndAuthenticateRequest: function (request) {
                request['actionName'] = 'testConnectAndAuthenticate';
                request['context'] = {}
            },
            getCheckSavedAuthExistsRequest: function (request) {
                request['actionName'] = 'checkSavedAuthExists';
                request['context'] = {};
            },
            getWriteSavedAuthRequest: function (request, password, authData) {
                request['actionName'] = 'writeSavedAuth';
                request['context'] = {
                    password: password,
                    authData: authData
                };
            },
            getReadSavedAuthRequest: function (request, password) {
                request['actionName'] = 'readSavedAuth';
                request['context'] = {
                    password: password
                };
            },
            getReadLicenseRequest: function (request) {
                request['actionName'] = 'readLicense';
                request['context'] = {};
            },
            getGetSystemVarsRequest: function (request) {
                request['actionName'] = 'getSystemVars';
                request['context'] = {};
            },
            getFetchRemoteFileRequest: function (request, remoteSource, destinationDirectory) {
                addSourceDestinationRequest(request, 'fetchRemoteFile', remoteSource, destinationDirectory);
            },
            getDownloadMultipleFilesRequest: function (request, baseDirectory, items) {
                request['actionName'] = 'downloadMultipleFiles';
                request['context'] = {baseDirectory: baseDirectory, items: items};
            },
            getSetApplicationSettingsRequest: function(request, applicationSettings){
                request['actionName'] = 'setApplicationSettings';
                request['context'] = {applicationSettings: applicationSettings};
            },
            getDeleteMultipleRequest: function (request, pathsAndTypes) {
                request['actionName'] = 'deleteMultiple';
                request['context'] = {pathsAndTypes: pathsAndTypes};
            },
            getExtractArchiveRequest: function (request, fileKey, fileIndexOffset, extractCount) {
                request['actionName'] = 'extractArchive';
                request['context'] = {fileKey: fileKey, fileIndexOffset: fileIndexOffset, extractCount: extractCount};
            },
            getUpdateLicenseRequest: function (request, license) {
                request['actionName'] = 'updateLicense';
                request['context'] = {license: license};
            },
            listDirectory: function (path, showHidden) {
                this.requestBody = this.getRequestBody();
                this.getListDirectoryRequest(this.requestBody, path, showHidden);
                return this._sendRequest(this.requestBody);
            },
            fetchFile: function (path) {
                this.requestBody = this.getRequestBody();
                this.getFetchFileRequest(this.requestBody, path, true);
                return this._sendRequest(this.requestBody);
            },
            rename: function (source, destination) {
                this.requestBody = this.getRequestBody();
                this.getRenameRequest(this.requestBody, source, destination);
                return this._sendRequest(this.requestBody);
            },
            changePermissions: function (path, mode) {
                this.requestBody = this.getRequestBody();
                this.getChangePermissionsRequest(this.requestBody, path, mode);
                return this._sendRequest(this.requestBody);
            },
            copy: function (source, destination) {
                this.requestBody = this.getRequestBody();
                this.getCopyRequest(this.requestBody, source, destination);
                return this._sendRequest(this.requestBody);
            },
            deleteFile: function (path) {
                this.requestBody = this.getRequestBody();
                this.getDeleteFileRequest(this.requestBody, path);
                return this._sendRequest(this.requestBody);
            },
            deleteDirectory: function (path) {
                this.requestBody = this.getRequestBody();
                this.getDeleteDirectoryRequest(this.requestBody, path);
                return this._sendRequest(this.requestBody);
            },
            makeDirectory: function (path) {
                this.requestBody = this.getRequestBody();
                this.getMakeDirectoryRequest(this.requestBody, path);
                return this._sendRequest(this.requestBody);
            },
            getFileContents: function (path) {
                this.requestBody = this.getRequestBody();
                this.getGetFileContentsRequest(this.requestBody, path);
                return this._sendRequest(this.requestBody);
            },
            putFileContents: function (path, contents) {
                this.requestBody = this.getRequestBody();
                this.getPutFileContentsRequest(this.requestBody, path, contents);
                return this._sendRequest(this.requestBody);
            },
            testConnectAndAuthenticate: function () {
                this.requestBody = this.getRequestBody();
                this.getTestConnectAndAuthenticateRequest(this.requestBody);
                return this._sendRequest(this.requestBody);
            },
            checkSavedAuthExists: function () {
                this.requestBody = this.getEmptyRequestBody();
                this.getCheckSavedAuthExistsRequest(this.requestBody);
                return this._sendRequest(this.requestBody);
            }, writeSavedAuth: function (password, authData) {
                this.requestBody = this.getEmptyRequestBody();
                this.getWriteSavedAuthRequest(this.requestBody, password, authData);
                return this._sendRequest(this.requestBody);
            }, readSavedAuth: function (password) {
                this.requestBody = this.getEmptyRequestBody();
                this.getReadSavedAuthRequest(this.requestBody, password);
                return this._sendRequest(this.requestBody);
            }, getLicense: function () {
                this.requestBody = this.getEmptyRequestBody();
                this.getReadLicenseRequest(this.requestBody);
                return this._sendRequest(this.requestBody);
            }, getSystemVars: function () {
                this.requestBody = this.getEmptyRequestBody();
                this.getGetSystemVarsRequest(this.requestBody);
                return this._sendRequest(this.requestBody);
            }, fetchRemoteFile: function (remoteSource, destinationDirectory) {
                this.requestBody = this.getRequestBody();
                this.getFetchRemoteFileRequest(this.requestBody, remoteSource, destinationDirectory);
                return this._sendRequest(this.requestBody);
            }, downloadMultipleFiles: function (baseDirectory, items) {
                this.requestBody = this.getRequestBody();
                this.getDownloadMultipleFilesRequest(this.requestBody, baseDirectory, items);
                return this._sendRequest(this.requestBody);
            }, setApplicationSettings: function(applicationSettings) {
                this.requestBody = this.getRequestBody();
                this.getSetApplicationSettingsRequest(this.requestBody, applicationSettings);
                return this._sendRequest(this.requestBody);
            }, deleteMultiple: function(pathsAndTypes) {
                this.requestBody = this.getRequestBody();
                this.getDeleteMultipleRequest(this.requestBody, pathsAndTypes);
                return this._sendRequest(this.requestBody);
            }, extractArchive: function (fileKey, fileIndexOffset, extractCount) {
                this.requestBody = this.getRequestBody();
                this.getExtractArchiveRequest(this.requestBody, fileKey, fileIndexOffset, extractCount);
                return this._sendRequest(this.requestBody);
            }, updateLicense: function (license) {
                this.requestBody = this.getRequestBody();
                this.getUpdateLicenseRequest(this.requestBody, license);
                return this._sendRequest(this.requestBody);
            }
        };
    }
}());